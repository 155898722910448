import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
// import Slider from 'components/Slider';

export default function Home() {
  	return (
   	<>
      	<Head>
				<title> Vancouver Sign Expert | Artisan Neon Craftsmanship & Signage Solutions</title>
				<meta name="title" content="Vancouver Sign Expert - Artisan Neon Craftsmanship & Signage Solutions" />
				<meta name="description" content="Discover Vancouver Sign Expert's unparalleled craftsmanship in artisan neon signs, precision channel letter fabrication, and high-resolution printing. We offer cutting-edge signage solutions in the Vancouver Lower Mainland area." />
				<meta name="keywords" content="Vancouver Sign Expert, Artisan Neon Craftsmanship, Custom Channel Letter Fabrication, Precision Sign Box Production, Pylon Sign Engineering, Large Format Printing, Vinyl Graphics, B2B Signage Manufacturing, Programmable LED Displays, Digital Sign Solutions, Video Display Systems, Interactive Kiosk Development, Website Design" />
				<meta name="location" content="Serving the Vancouver Lower Mainland area" />
				<meta name="services" content="Custom Channel Letters, Artisan Neon Signs, Sign Box Fabrication, Pylon Sign Engineering, Large Format Printing, Vinyl Graphics, B2B Signage Manufacturing, Programmable LED Displays, Digital Sign Solutions, Video Displays, Interactive Kiosks, Professional Website Design Services" />
				<meta name="usp" content="Elevate Your Brand with Vancouver's Premier Signage and Neon Craftsmanship Experts" />
				<meta name="contact" content="Contact us for personalized sign solutions in Vancouver. Get in touch for expert signage services in the Lower Mainland area." />
				<meta name="cta" content="Explore our artisan sign portfolio and request a quote today!" />
			</Head>
			<>
				<header className='hero-content-center'>
					<div className='overlay-dark'>
						<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5omivr-people-2601878_1280.jpg' alt='slider' fill priority={ true } />
					</div>
					<div className='hero-content-text'>
						<h1>
							Your One-Stop Destination for Exceptional Signage Services
						</h1>
						<h2>
							Harness the Power of Visionaries in Signage Craftsmanship. Unlock Your Signage Potential. Crafting Your Unique Identity for a Vibrant Tomorrow.
						</h2>
						<Link href='mailto:neonlight@inbox.com'>
							Consult Our Experts Now
						</Link>
					</div>
				</header>
				{/* <header className='hero img slider'>
					<Slider>
						<div>
							<div media='mobile'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5ativt-OJ-mobile.jpg' alt='slider' fill priority={ true } />
							</div>
							<div media='desktop'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5ativ9-OJ.jpg' alt='slider' fill priority={ true } />
							</div>
						</div>
						<div>
							<div media='mobile'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atiw9-Science_Guy-mobile.jpg' alt='slider' fill loading='lazy' />
							</div>
							<div media='desktop'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atisi-Science_Guy.jpg' alt='slider' fill loading='lazy' />
							</div>
						</div>
						<div>
							<div media='mobile'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atiru-Vinyl_Graphics-mobile.jpg' alt='slider' fill loading='lazy' />
							</div>
							<div media='desktop'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atith-Vinyl_Graphics.jpg' alt='slider' fill loading='lazy' />
							</div>
						</div>
						<div>
							<div media='mobile'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atiur-3D_Letters-mobile.jpg' alt='slider' fill loading='lazy' />
							</div>
							<div media='desktop'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atiul-3D_Letters.jpg' alt='slider' fill loading='lazy' />
							</div>
						</div>
						<div>
							<div media='mobile'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atira-Vehicle_Graphics-mobile.jpg' alt='slider' fill loading='lazy' />
							</div>
							<div media='desktop'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5atit0-Vehicle_Graphics.jpg' alt='slider' fill loading='lazy' />
							</div>
						</div>
					</Slider>
				</header> */}
				<div className='main-content'>
					<section className='three-card'>
						<h1>Discover the Signage Advantage</h1>
						<article>
							<div>
								<p>We elevate</p>
								<p>Elevate your signage experience with our unmatched expertise and attention to detail.</p>
							</div>
							<div>
								<p>We innovate</p>
								<p>Experience innovative solutions that set your business apart and engage your audience.</p>
							</div>
							<div>
								<p>We deliver</p>
								<p>We're committed to delivering comprehensive signage solutions tailored to your unique needs.</p>
							</div>
						</article>
					</section>
					<section className='grid-cube full-bg-light'>
						<h1>Our Services</h1>
						<p>
							Whether you need repairs, installations, or simply want to brighten your business prospects, we've got the expertise to make your vision shine.
						</p>
						<article>
							<div>
								<p>Custom Channel Letter Fabrication</p>
								<p>
									Standout Signage: Elevate your business visibility with custom channel letters that grab attention and leave a lasting impression.
									<Link href='/services#custom-channel-letter-fabrication' className='link-cta'>Read More <span className='visually-hidden'>about Custom Channel Letter Fabrication</span></Link>
								</p>
							</div>
							<div>
								<p>High-Resolution Large Format Printing</p>
								<p>
									Vibrant Visuals: Experience the power of high-resolution large format printing, bringing your graphics and promotions to life with unmatched clarity and vibrancy.
									<Link href='/services#high-resolution-large-format-printin' className='link-cta'>Read More <span className='visually-hidden'>about High-Resolution Large Format Printing</span></Link>
								</p>
							</div>
							<div>
								<p>Expert Vinyl Graphics Application</p>
								<p>
									Graphics That Stick: Our skilled team ensures precise vinyl graphics application, turning any surface into a canvas for your branding and messaging.
									<Link href='/services#expert-vinyl-graphics-application' className='link-cta'>Read More <span className='visually-hidden'>about Expert Vinyl Graphics Application</span></Link>
								</p>
							</div>
							<div>
								<p>Cutting-Edge Programmable LED Displays</p>
								<p>
									Dynamic Messaging: Capture attention with programmable LED signs that deliver dynamic, eye-catching messages and promotions.
									<Link href='/services#cutting-edge-programmable-led-displays' className='link-cta'>Read More <span className='visually-hidden'>about Cutting-Edge Programmable LED Displays</span></Link>
								</p>
							</div>
							<div>
								<p>Advanced Digital Sign Solutions</p>
								<p>
									Modern Signage Solutions: Embrace the future with digital signs that provide versatility and impact, keeping your messaging fresh and engaging.
									<Link href='/services#advanced-digital-sign-solutions' className='link-cta'>Read More <span className='visually-hidden'>about Advanced Digital Sign Solutions</span></Link>
								</p>
							</div>
							<div>
								<p>Professional Website Design Services</p>
								<p>
									Digital Presence: Elevate your online presence with a professionally designed website that attracts, informs, and converts visitors into customers.
									<Link href='/services#professional-website-design-services' className='link-cta'>Read More <span className='visually-hidden'>about Professional Website Design Services</span></Link>
								</p>
							</div>
						</article>
					</section>
					<section className='content-right'>
						<h1>Why Choose Us</h1>
						<p>Signage Challenges and Solutions</p>
						<article>
							<div className='left'>
								<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8ln5s1pjb-pexels-photo-1058100.jpeg' alt='' fill loading='lazy' sizes='(max-width: 768px) 100vw, 33vw' />
							</div>
							<div className='right'>
								<h1>
									<strong>Ineffective Signage Impedes Growth.</strong> Small businesses often face challenges in signage that impact their success. Common problems include:
								</h1>
								<ul>
									<li>
										<strong>Visibility Woes:</strong> Signs fail to catch potential customers' eyes, resulting in missed opportunities.
									</li>
									<li>
										<strong>Inconsistent Branding:</strong> Maintaining a unified brand image across signage elements can be challenging.
									</li>
									<li>
										<strong>Budget Constraints:</strong> Operating on limited budgets, businesses seek cost-effective, quality signage solutions.
									</li>
									<li>
										<strong>Regulatory Compliance:</strong> Navigating local regulations and obtaining permits can be complex.
									</li>
									<li>
										<strong>Obsolete Signage:</strong> Businesses may be stuck with outdated signage as technology evolves.
									</li>
								</ul>
								<p>
									<strong>Consequences of Neglected Signage.</strong> Imagine potential customers passing by, unaware of your business due to unengaging signage. Competitors with captivating signs thrive.
								</p>
								<p>
									<strong>Unlock Your Signage Potential:</strong> We specialize in solving common signage issues with strategic planning, brand consistency, budget-friendly excellence, regulatory expertise, and future-proof designs. Transform your business – schedule a consultation today!
								</p>
							</div>
						</article>
					</section>
					{/* <section className='three-card'>
						<h1>Illuminate Your Success</h1>
						<p>Lighting Solutions that Shine</p>
						<article>
							<div>
								<p>Consultation</p>
								<p>Bright Ideas for Your Business!</p>
								<p>Our expert consultants provide tailored solutions for your lighting and neon sign needs. Let us light up your path to success.</p>
							</div>
							<div>
								<p>Strategy</p>
								<p>A Brighter Tomorrow Starts Here!</p>
								<p>Crafting effective lighting strategies that enhance your ambiance and attract customers, all while saving you money.</p>
							</div>
							<div>
								<p>Integration</p>
								<p>Lighting Brilliance in Harmony!</p>
								<p>Seamlessly blending design, installation, and maintenance to create a luminous synergy that elevates your business.</p>
							</div>
						</article>
					</section>
					<hr />
					<section className='content-right'>
						<h1>
							About Vancouver Sign Expert
						</h1>
						<article>
							<div className='left'>
								<Image src="https://d1kdcmsg84yapz.cloudfront.net/files/8lmlkaev9-open-sign.jpg" alt='Open Sign' fill loading='lazy' sizes='(max-width: 768px) 100vw, 33vw' />
							</div>
							<div className='right'>
								<p>
									Vancouver Sign Expert is a premier lighting solutions provider dedicated to illuminating your business's success. With a rich history dating back to 1985, we specialize in creating, repairing, and installing innovative lighting and signage solutions. Our commitment is to enhance your brand's visibility and captivate your audience through cutting-edge lighting technology. We offer comprehensive services, including design, manufacturing, repair, and installation, to meet all your lighting needs and make your business shine brightly.
								</p>
								<p><Link href="/about" className='btn-dark-outline'>Read More <span className='visually-hidden'>about Vancouver Sign Expert</span></Link></p>
							</div>
						</article>
					</section> */}
					<section className='full-width'>
						<h1>Where To Find Us</h1>
						<article>
							<iframe title='Google Maps Location' className='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83417.28899437931!2d-123.01716679997385!3d49.20453786059702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486780dd07651b5%3A0x57abd57fd116837f!2sVancouver%20Sign%20Services!5e0!3m2!1sen!2sca!4v1696087991074!5m2!1sen!2sca" width="100%" height="400" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
						</article>
					</section>
				</div>
			</>
    	</>
  	)
}
